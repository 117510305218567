import { JWMLink } from "@components/JWMLink/JWMLink";
import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "../../../static/svgs/platform-logo/sprite.svg";
import JanssenLogo from "../../../static/svgs/platform-logo/sprite_janssen.svg";
import style from "./style.module.scss";

interface PropTypes {
    variant?: "default" | "footer";
    position?: "header" | "footer" | "default";
    custom?: ImageQueryResponse;
    id?: string;
    onClick: () => void;
}

const LOGO_MAP = {
    default: { image: `${Logo}#JnJ_withMe`, height: 44, width: 113 },
    footer: { image: `${Logo}#JJ_IM_Logo_SingleLine_White_RGB`, height: 66, width: 166 },
};

const JANSSEN_LOGO_MAP = {
    default: { image: `${JanssenLogo}#logo_default`, height: 44, width: 113 },
    footer: { image: `${JanssenLogo}#logo_footer`, height: 103, width: 243 },
};

const MAX_HEIGHT = 56; // This should be the same as var(--header-main-navigation-height) in the CSS

/**
 * Renders the platform logo to be displayed in different places.
 * It can be the default logo, the footer logo, or a variant defined in CS.
 *
 * Depending of the value of GATSBY_HOME_URL it can have a link or not.
 */
export const PlatformLogo = (props: PropTypes): JSX.Element => {
    const { variant = "default", position = "default", custom, id, ...other } = props;
    const { t } = useTranslation();
    const { jnjBranded } = useJnjBranding();

    const logoMap = jnjBranded ? LOGO_MAP : JANSSEN_LOGO_MAP;

    const altText =
        variant === "footer"
            ? t("Logo Janssen | Pharmaceutical Companies of Johnson & Johnson", { ns: "common" })
            : t("Homepage", { ns: "common" });

    const dataTestId = custom?.url ? "logo-custom" : `logo-${variant}-${position}`;

    const calculateDimensions = (
        height: number,
        width: number,
        maxHeight: number = undefined as number,
        forcedHeight: number = undefined as number,
    ): { height: number; width: number } => {
        const finalHeight = forcedHeight ? forcedHeight : Math.min(height, maxHeight);
        const finalWidth = Math.round((width * finalHeight) / height);

        return { height: finalHeight, width: finalWidth };
    };

    const dimensions = calculateDimensions(
        custom?.url ? custom?.dimension?.height : logoMap[variant].height,
        custom?.url ? custom?.dimension?.width : logoMap[variant].width,
        MAX_HEIGHT,
        position === "header" ? MAX_HEIGHT : undefined,
    );

    const logoImage = (
        <img
            src={custom?.url ? custom?.url : logoMap[variant].image}
            alt={altText}
            id={id ? id : dataTestId}
            data-test-id={dataTestId}
            height={dimensions.height}
            width={dimensions.width}
        />
    );

    return (
        <div className={style.logo} {...other}>
            {process.env.GATSBY_HOME_URL === "false" || position !== "header" ? (
                logoImage
            ) : (
                <JWMLink
                    name={t("Homepage", { ns: "common" })}
                    placement="page:Home | navigation"
                    url={process.env.GATSBY_HOME_URL || "/"}
                    ariaLabel={""}
                    imageLink={true}
                    external={!!process.env.GATSBY_HOME_URL}
                >
                    {logoImage}
                </JWMLink>
            )}
        </div>
    );
};

export default PlatformLogo;
