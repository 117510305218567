import { useLocalStorage } from "usehooks-ts";

export enum BRANDING_TYPE {
    NONE = "false",
    BASIC = "basic",
    FULL = "full",
}

/**
 * Check if the site is JnJ branded by looking at the GATSBY_JNJ_BRANDED environment variable.
 *
 * Modes:
 *  - Off: No JnJ branding applied
 *  - Basic: JnJ branded with new logos, colors and fonts
 *      Attribute [data-jnj-branded=true] will be set on the document html element
 *  - Full: JnJ fully branded with custom components and styles
 *      Attributes [data-jnj-branded=true] and [data-jnj-full-branded=true] will be set on the document html element
 *
 * Values for GATSBY_JNJ_BRANDED are as follows:
 *  - false: Not JnJ branded
 *  - true: JnJ basic branding
 *  - basic: JnJ basic branding
 *  - full: JnJ fully branding
 *
 * @returns [jnjBranded, jnjFullBranded, setJnjBranding]
 */
export const useJnjBranding = () => {
    const brandSetting = process.env.GATSBY_JNJ_BRANDED;

    const isJnJBranded =
        brandSetting === "true" || brandSetting === BRANDING_TYPE.BASIC || brandSetting === BRANDING_TYPE.FULL;
    const isJnjFullBranded = brandSetting === BRANDING_TYPE.FULL;

    const [jnjBranded, setJnJBranded] = useLocalStorage("jnjBranded", isJnJBranded);
    const [jnjFullBranded, setJnJFullBranded] = useLocalStorage("jnjFullBranded", isJnjFullBranded);

    // Setting the branding on will always enable the full branding.
    // This function is meant to be called only from the easter egg in the footer.
    const setJnjBranding = (isJnJBranded: boolean): void => {
        if (process.env.GATSBY_ENVIRONMENT === "prod") return;

        const newState = isJnJBranded ? "true" : "false";
        console.log(`Setting data-jnj-branded and data-jnj-full-branded to ${newState}`);

        document?.documentElement.setAttribute("data-jnj-branded", newState);
        document?.documentElement.setAttribute("data-jnj-full-branded", newState);

        setJnJBranded(isJnJBranded);
        setJnJFullBranded(isJnJBranded);
    };

    return { jnjBranded, jnjFullBranded, setJnjBranding };
};

export default useJnjBranding;
