import React from "react";
import { AutoCompleteType } from "../types";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classnames from "classnames";
import { mdiMagnify } from "@mdi/js";
import style from "./style.module.scss";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";

export const Suggestions = ({
    items,
    autocomplete,
    source,
}: {
    items: Record<string, unknown>[];
    autocomplete: AutoCompleteType;
    source: { sourceId: string };
}): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(BreakPoint.md);

    return (
        <div data-test-id="most-popular-searches">
            <div className={style.popular}>
                <Typography font="title" size={isMobile ? "3xl" : "l"} color="dark" weight="500">
                    {t("Most popular searches", { ns: "search" })}
                </Typography>
            </div>
            <ul className="aa-List" {...autocomplete?.getListProps()}>
                {items?.slice(0, 5)?.map((item, index) => (
                    <li
                        key={`suggestions-${source?.sourceId}-${item?.query}-${index}`}
                        className={classnames("aa-Item", style.item)}
                        data-test-id={`most-popular-search-${item?.query}`}
                        {...autocomplete?.getItemProps({
                            item,
                            source,
                        })}
                    >
                        <Typography color="light" size={isMobile ? "m" : "s"}>
                            <div className={style.resultWrapper}>
                                <Icon
                                    data-test-id={`search-box-result-icon`}
                                    icon={mdiMagnify}
                                    verticalAlignMiddle
                                    size={isMobile ? "medium" : "xs"}
                                    color="text-light"
                                />{" "}
                                <span className={style.oneLineEllipsis}>{item?.query}</span>
                            </div>
                        </Typography>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Suggestions;
