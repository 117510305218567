import { ErrorMessage } from "@components/ErrorMessage/ErrorMessage";
import { LinkClickImpression } from "@jmc/core/src/components/LinkClickImpression/index";
import { LinkButton } from "@jmc/solid-design-system/src/components/atoms/LinkButton/LinkButton";
import useIsClient from "@jmc/utils/hooks/useIsClient";
import React from "react";
import { useTranslation } from "react-i18next";
import regexifyString from "regexify-string";

import { login } from "../../../api/auth";

export const ShowErrorMessage = (): JSX.Element => {
    const { t } = useTranslation();
    const isClient = useIsClient();

    if (isClient) {
        const queryParams = new URLSearchParams(window?.location?.search);
        const errorCode = queryParams?.get("errorCode");

        if (errorCode) {
            let message = "" as (string | JSX.Element)[] | string;
            let closeAfterSec = undefined;

            switch (errorCode) {
                case "MarketingTokenExpired":
                    message = t("Session token expired, please login again.", { ns: "login" });
                    break;
                case "InvalidAccount":
                    message = t("Account isn't valid because of pending email validation or user identification", {
                        ns: "login",
                    });
                    break;
                case "InvalidCountry":
                    message = t("The user's country is not a valid country defined by the frontend configuration", {
                        ns: "login",
                    });
                    break;
                case "InactiveLoggedOut":
                    message = t("Logged out due to inactivity. Please, {{LOGIN_LINK}} to login again.", {
                        LOGIN_LINK: "[Login link]",
                        ns: "login",
                    });

                    message = regexifyString({
                        pattern: /\[.*?\]/gim,
                        decorator: () => (
                            <LinkClickImpression
                                name="Login"
                                targetUrl={window?.location?.pathname}
                                placement="page:snackbar"
                                external={false}
                            >
                                <LinkButton onClick={() => login()}>{t("click here", { ns: "common" })}</LinkButton>
                            </LinkClickImpression>
                        ),
                        input: message as string,
                    });

                    closeAfterSec = 0;
                    break;
                case "UnknownError":
                default:
                    message = t("An unknown error occurred", { ns: "errors" });
            }

            return <ErrorMessage message={message as string} closeAfterSec={closeAfterSec} />;
        }
    }
    return null;
};

export default ShowErrorMessage;
