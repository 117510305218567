import React, { ReactNode } from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { ModalConsumer } from "./Modal";
import style from "./style.module.scss";

export interface PropTypes {
    children: ReactNode | ReactNode[];
    align?: "start" | "center" | "end";
}

export const Content = ({ children, align = "start" }: PropTypes): JSX.Element => (
    <ModalConsumer>
        {() => (
            <div data-test-id="Modal.Content" className={style[`align_${align}`]}>
                <Typography>{children}</Typography>
            </div>
        )}
    </ModalConsumer>
);
