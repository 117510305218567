import { useQSSKey } from "@contexts/QueryStringStorageContext";
import { useSiteReference } from "@contexts/SiteContext";
import { LinkClickImpression } from "@jmc/core/src/components/LinkClickImpression/index";
import { useHeaderProperties } from "@jmc/core/src/hooks/useHeaderProperties/useHeaderProperties";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Badge } from "@jmc/solid-design-system/src/components/atoms/Badge/Badge";
import useIsClient from "@jmc/utils/hooks/useIsClient";
import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { globalHistory } from "@reach/router";
import getInternalLink from "@utils/getInternalLink";
import { default as jwmAnchorScroll } from "@utils/jwmAnchorScroll";
import classnames from "classnames";
import { Link } from "gatsby";
import Trigger from "rc-trigger";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { setLocaleCookie } from "../../utils/setLocaleCookie";
import { ExternalLinkDisclaimer } from "./ExternalLinkDisclaimer/ExternalLinkDisclaimer";
import style from "./style.module.scss";

export const regexAnonymizeEmail = /(?!.*:).*@/;

interface PropTypes {
    ariaLabel?: string;
    title?: string;
    anchor_id?: string;
    locale?: string;
    url: string;
    url_prefix?: string;
    params?: { [key: string]: string };
    external?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    children: ReactNode;
    imageLink?: boolean;
    name?: string;
    placement?: string;
    newWindow?: boolean; // Force opening in new window
    shouldTrackLinkClick?: boolean;
    enableAnchorScroll?: boolean;
    tabIndex?: number;
    expandHitbox?: boolean;
    sendOrigin?: boolean; // Whether to send the page the link is coming from along
    id?: string;
}

/**
 * Wraps the Gatsby Link component (which prefetches links) and injects the locale subPath when necessary.
 *
 * We cannot use target="_blank" with the <Link> component, so if we want to open an internal link in
 * another tab, we need to use the <a> component.
 */
export const JWMLink = (props: PropTypes): JSX.Element => {
    const [openModal, setOpenModal] = React.useState(false);
    const isClient = useIsClient();

    const {
        ariaLabel = "",
        children,
        anchor_id = "",
        url,
        url_prefix = "",
        external = false,
        fullWidth = true,
        disabled = false,
        imageLink = false,
        name,
        placement,
        newWindow = false,
        locale = "",
        shouldTrackLinkClick = true,
        enableAnchorScroll = true,
        tabIndex = 0,
        sendOrigin = false,
        expandHitbox = false,
        ...other
    } = props;

    const currentLocale = useLocale();
    const currentSite = useSiteReference();
    const localePrefix = locale || currentLocale;
    const hashKey = useQSSKey();
    const { params } = props;
    const { t } = useTranslation();
    const headerProps = useHeaderProperties();

    const classNames = classnames(
        style.element,
        disabled ? style.disabled : null,
        fullWidth ? style.fullWidth : null,
        imageLink ? style.imageLink : null,
        expandHitbox ? style.expandHitbox : null,
    );

    const urlStripped = stripSlash.strip(url);
    const hasProtocol =
        urlStripped?.startsWith("http") || urlStripped?.startsWith("mailto:") || urlStripped?.startsWith("tel:");

    let currentPage = false;
    let finalUrl = urlStripped;

    const mailtoUrl = url?.startsWith("mailto");

    if (!external || !hasProtocol) {
        finalUrl = getInternalLink(localePrefix, url_prefix, url, params);

        // Check if the URL matches the current one, for anchor link control.
        if (encodeURI(finalUrl) === globalHistory?.location?.pathname) {
            currentPage = true;
        }

        //Check if anchor_id contains # in case that "a" element from ContentStack text editor is set as anchor (ex. #link-to-anchor)
        if (anchor_id) {
            finalUrl = anchor_id.startsWith("#")
                ? finalUrl.replace(`/${localePrefix}`, "") + "#" + anchor_id.substring(1)
                : finalUrl + "#" + anchor_id;
        }
        // Fix case when site was not set initially for internal link
        if (!url_prefix && currentSite?.url_prefix && url && !url?.startsWith("/")) {
            finalUrl = "/" + getInternalLink(localePrefix, currentSite?.url_prefix, url, params);
        }
    } else if (anchor_id) {
        // Append anchor at the end of a external link if needed.
        finalUrl = finalUrl + (anchor_id.startsWith("#") ? anchor_id : "#" + anchor_id);
    }

    if (mailtoUrl) {
        finalUrl = url;
    }

    // Hide email from analytics for mailto links.
    let anonymizedUrl = "";
    const phoneNumberUrl = url?.startsWith("tel:");
    const phoneNumberText = "phone-number";

    if (mailtoUrl) {
        anonymizedUrl = finalUrl.replace(regexAnonymizeEmail, "email@");
        if (anonymizedUrl.includes("?")) anonymizedUrl = anonymizedUrl.slice(0, anonymizedUrl.indexOf("?"));
    }

    const onClick = (e: Event): void => {
        e.preventDefault();
        history.replaceState(null, "", anchor_id?.startsWith("#") ? anchor_id : "#" + anchor_id);

        // Dispachting an event cause the replaceState call used here
        // wont trigger a change in location or history objects.
        window.dispatchEvent(new Event("hashchange"));

        if (enableAnchorScroll) {
            jwmAnchorScroll(anchor_id, headerProps, true);
        }
    };

    const cleanAriaLabel = (): string => {
        if (ariaLabel === null) {
            return null;
        } else if (ariaLabel) {
            return ariaLabel;
        } else if (typeof children === "string") {
            return children;
        } else {
            return "";
        }
    };

    if (external) {
        if (url?.startsWith("mailto") || url?.startsWith("tel:") || !hasProtocol) {
            if (shouldTrackLinkClick) {
                return (
                    <LinkClickImpression
                        name={
                            mailtoUrl
                                ? name?.replace(regexAnonymizeEmail, "email@")
                                : phoneNumberUrl
                                ? phoneNumberText
                                : name
                        }
                        targetUrl={mailtoUrl ? anonymizedUrl : phoneNumberUrl ? `callto ${phoneNumberText}` : finalUrl}
                        imageLink={imageLink}
                        external={external}
                        placement={placement}
                    >
                        <a
                            aria-label={cleanAriaLabel()}
                            className={classNames}
                            href={finalUrl}
                            data-test-id="ExternalLink"
                            onClick={currentPage || anchor_id?.startsWith("#") ? onClick : setLocaleCookie}
                            {...other}
                        >
                            {children}
                        </a>
                    </LinkClickImpression>
                );
            } else {
                return (
                    <a
                        aria-label={cleanAriaLabel()}
                        className={classNames}
                        href={finalUrl}
                        data-test-id="ExternalLink"
                        onClick={currentPage || anchor_id?.startsWith("#") ? onClick : setLocaleCookie}
                        {...other}
                    >
                        {children}
                    </a>
                );
            }
        } else {
            return (
                <ExternalLinkDisclaimer
                    {...other}
                    ariaLabel={cleanAriaLabel()}
                    name={name}
                    imageLink={imageLink}
                    targetUrl={mailtoUrl ? anonymizedUrl : finalUrl}
                    url={finalUrl}
                    placement={placement}
                    openModal={openModal}
                    className={classNames}
                    onClose={(): void => {
                        setOpenModal(false);
                        Trigger.defaultProps.autoDestroy = true;
                    }}
                    onClick={(e: Event): void => {
                        e.preventDefault();
                        // set trigger to false for allowing disclaimer to open
                        Trigger.defaultProps.autoDestroy = false;
                        setOpenModal(true);
                    }}
                >
                    {children}
                </ExternalLinkDisclaimer>
            );
        }
    } else {
        let stateObj = {};
        if (anchor_id) {
            stateObj = { anchor: anchor_id?.startsWith("#") ? anchor_id?.substring(1) : anchor_id };
        }
        if (sendOrigin && isClient && hashKey) {
            stateObj = {
                ...stateObj,
                origin: `${window.location.origin}${window.location.pathname}`,
                hashKey: hashKey,
            };
        }

        let warningLabel = null;
        // Check if we are in a standalone site and link goes to a different site
        if (currentSite?.standalone && process.env.GATSBY_ENVIRONMENT !== "prod") {
            if (
                (url_prefix && currentSite?.url_prefix !== url_prefix) ||
                (!url_prefix &&
                    !url?.startsWith(currentSite?.url_prefix) &&
                    !url?.startsWith(`/${currentSite?.url_prefix}`))
            ) {
                warningLabel = (
                    <div className={style.warning}>
                        <Badge color="warning" padding="xs" id="Standalone.Warning">
                            {t("CHECK!", { ns: "common" })}
                        </Badge>
                    </div>
                );
            }
        }

        // check if we are in a patient declaration page then there will be no warning label
        const isBrowser = typeof document !== "undefined";
        const patientDeclarationPageUid = isBrowser
            ? JSON.parse(sessionStorage?.getItem("patientDeclaration") || "null") || []
            : [];
        if (!patientDeclarationPageUid?.length && currentSite?.patient_declaration?.length) {
            warningLabel = null;
        }

        return (
            <LinkClickImpression
                name={name}
                targetUrl={mailtoUrl ? anonymizedUrl : finalUrl}
                imageLink={imageLink}
                external={external}
                placement={placement}
            >
                {newWindow ? (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                        aria-label={cleanAriaLabel()}
                        className={warningLabel ? classnames(classNames, style.warningLink) : classNames}
                        href={finalUrl}
                        data-test-id="InternalLink"
                        onClick={currentPage || anchor_id?.startsWith("#") ? onClick : setLocaleCookie}
                        target="_blank"
                        {...other}
                    >
                        {children}
                        {warningLabel}
                    </a>
                ) : (
                    <Link
                        aria-label={cleanAriaLabel()}
                        className={warningLabel ? classnames(classNames, style.warningLink) : classNames}
                        to={finalUrl}
                        onClick={currentPage || anchor_id?.startsWith("#") ? onClick : setLocaleCookie}
                        state={stateObj}
                        tabIndex={tabIndex}
                        {...other}
                    >
                        {children}
                        {warningLabel}
                    </Link>
                )}
            </LinkClickImpression>
        );
    }
};

export default JWMLink;
