import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "../../types/EventTypes";
import React, { useRef, useEffect, useState } from "react";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";

export interface PropTypes {
    eventType: EventTypes.TOOL_STEP | EventTypes.TOOL_LASTSTEP | EventTypes.TOOL_ERROR;
    name: string;
    type?: string;
    stepnumber: string;
    stepname: string;
    result?: unknown;
    fieldname?: string;
    errormessage?: string;
    errortype?: "user" | "system";
    targeturl?: string;
}

/**
 * Implements tracking for tools (i.e. forms).
 *
 * This component will send the event only once, the first time is rendered, to avoid sending
 * multiple events due to the component being re-render for any reason.
 *
 * Tip: If your form has multiple instances of this component happening, you will need to add
 * a different key parameter to each one, so the component will send all the events properly.
 */
export const ToolTracking: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { eventType, type = "form", ...eventData } = props;
    const fullEventData = { type, ...eventData };

    const [sent, setSent] = useState(false);
    const formContainer = useRef<HTMLDivElement>(null);
    const inViewport = useOnScreen(formContainer);

    useEffect(() => {
        //We dont need to check if the element is in viewport for errors or last_steps. We can send these events as soon as they exist in the DOM
        if (!sent && !(eventType === EventTypes.TOOL_STEP && !inViewport)) {
            setTimeout(() => {
                analyticstracker().trackEvent({
                    event: eventType,
                    info: fullEventData,
                });
            }, 1000);
            setSent(true);
        }
    }, [inViewport]);

    return (
        <div
            role="none"
            data-tracking-event={eventType}
            data-tracking-info={JSON.stringify(fullEventData)}
            ref={formContainer}
        />
    );
};

export default ToolTracking;
