import { JWMHtml } from "@components/JWMHtml/JWMHtml";
import { JWMLink } from "@components/JWMLink/JWMLink";
import PlatformLogo from "@components/PlatformLogo/PlatformLogo";
import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { cleanCSSIdentifier } from "@jmc/utils/utils/clean-css-identifier";
import { formatDate } from "@jmc/utils/utils/format-date";
import { CMSSocialMediaLinks } from "@jwm/types/CMSFooter";
import { CMSLegalDocument } from "@jwm/types/CMSLegalDocuments";
import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiYoutube } from "@mdi/js";
import { mapLinkGroupProps } from "@utils/cms-mapper/mapLinkGroupProps";
import classnames from "classnames";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import icons from "../../../static/svgs/footer/sprite.svg";
import style from "./style.module.scss";

interface Props {
    disable_last_updated_date?: boolean;
    disclaimer: string;
    imr_number?: string;
    legal_entity_name?: string;
    legal_links: CMSLegalDocument[];
    logo?: ImageQueryResponse;
    social_media: CMSSocialMediaLinks;
    updatedAt?: string;
    addBottomMargin?: boolean;
}

export const Footer = (props: Props): JSX.Element => {
    const {
        disable_last_updated_date = false,
        disclaimer,
        imr_number,
        legal_entity_name,
        legal_links,
        logo,
        social_media,
        updatedAt,
        addBottomMargin = false,
    } = props;
    const { t } = useTranslation();
    const { jnjBranded, setJnjBranding } = useJnjBranding();

    const socialMediaIcons = {
        facebook: mdiFacebook,
        linkedin: mdiLinkedin,
        youtube: mdiYoutube,
        instagram: mdiInstagram,
    };

    const lastUpdated = (
        <span data-test-id="footer.lastUpdated">
            {t("Last updated", { ns: "common" })} {formatDate(updatedAt, "LL")}
        </span>
    );

    const classNames = classnames(style.element, addBottomMargin ? style.addBottomMargin : null);
    const finalDisclaimer = disclaimer?.replace(
        "{{LEGAL_ENTITY_NAME}}",
        t("Janssen Pharmaceutica NV", { ns: "common" }),
    );

    // Make it possible to switch the branding from the browser console
    if (typeof window !== "undefined" && window.setJnjBranding === undefined) window.setJnjBranding = setJnjBranding;

    // toggle branding whenever someone clicks 5 times on the footer logo within 500ms
    let timeoutId: string | number | NodeJS.Timeout = null;
    const nrOfClicks = useRef(0);

    const onClick = (): void => {
        timeoutId && clearTimeout(timeoutId);
        nrOfClicks.current++;
        if (nrOfClicks.current === 5) {
            setJnjBranding(!jnjBranded);
        }
        timeoutId = setTimeout(() => {
            nrOfClicks.current = 0;
        }, 500);
    };

    return (
        <div className={classNames} data-test-id="footer">
            <div
                className={style.textWrapper}
                title={`Janssen With Me v${process.env.GATSBY__VERSION__ || "0.0.0"}-${
                    process.env.GATSBY__COMMIT_HASH__
                }`}
            >
                <div className={style.container}>
                    <div className={style.content}>
                        <div className={style.footer}>
                            <div className={style.disclaimer} data-test-id="footer.disclaimer">
                                <Typography color="white" size="s" variant="body">
                                    <p>
                                        {legal_entity_name &&
                                            `${
                                                legal_entity_name === "{{LEGAL_ENTITY_NAME}}"
                                                    ? t("Janssen Pharmaceutica NV", { ns: "common" })
                                                    : legal_entity_name
                                            } — `}
                                        {!disable_last_updated_date && lastUpdated}
                                        {imr_number &&
                                            (disable_last_updated_date ? `${imr_number}` : ` — ${imr_number}`)}
                                    </p>
                                </Typography>
                                <JWMHtml color="white" showGlossary={false}>
                                    {finalDisclaimer}
                                </JWMHtml>
                            </div>
                        </div>
                    </div>
                    <div className={style.logo} data-test-id="footer.logo">
                        <PlatformLogo position="footer" variant="footer" custom={logo} onClick={onClick} />
                    </div>
                </div>
            </div>
            <div className={style.linksWrapper}>
                <div className={style.container}>
                    {Object.entries(social_media ?? [])?.length > 0 && (
                        <div className={style.social_media}>
                            {Object.entries(social_media ?? [])?.map((social, index) => {
                                if (social[1]) {
                                    return (
                                        <JWMLink
                                            key={index}
                                            external
                                            fullWidth={false}
                                            url={social[1] as string}
                                            ariaLabel={social[0] === "twitter" ? "X" : social[0]}
                                            title={social[0] === "twitter" ? "X" : social[0]}
                                        >
                                            {social[0] === "twitter" ? (
                                                <span id={social[0]}>
                                                    <img src={`${icons}#x_icon`} className={style.xIcon} alt="X" />
                                                </span>
                                            ) : (
                                                <Icon
                                                    color="white"
                                                    icon={socialMediaIcons[social[0]]}
                                                    size="large"
                                                    id={social[0]}
                                                ></Icon>
                                            )}
                                        </JWMLink>
                                    );
                                }
                            })}
                        </div>
                    )}
                    {(legal_links?.length > 0 || process.env.GATSBY_ONETRUST_DATA_DOMAIN) && (
                        <div className={style.links} data-test-id="footer.links">
                            {legal_links?.length > 0 &&
                                legal_links.map((link, index: number) => {
                                    const linkGroup = mapLinkGroupProps(link?.link_group);
                                    const title = linkGroup?.external
                                        ? link?.link_group?.external?.title
                                        : link?.link_group?.internal?.[0]?.title || link?.title;
                                    const url = link?.onetrust_privacy_policy_id ? "/privacy-policy" : linkGroup?.url;
                                    return (
                                        <JWMLink
                                            key={index}
                                            url={url}
                                            url_prefix={linkGroup?.url_prefix}
                                            fullWidth={false}
                                            external={linkGroup?.external}
                                            ariaLabel={title}
                                        >
                                            <Typography
                                                link
                                                data-test-id={title}
                                                size="s"
                                                id={cleanCSSIdentifier(title)}
                                            >
                                                {title}
                                            </Typography>
                                        </JWMLink>
                                    );
                                })}
                            {process.env.GATSBY_DISABLE_COOKIEBANNER === "false" &&
                                process.env.GATSBY_ONETRUST_DATA_DOMAIN && (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window?.OneTrust?.ToggleInfoDisplay();
                                        }}
                                        className={style.link}
                                    >
                                        <Typography
                                            data-test-id="Footer.CookieSettings"
                                            id={cleanCSSIdentifier(t("Cookie Settings", { ns: "navigation" }))}
                                            size="s"
                                        >
                                            {t("Cookie Settings", { ns: "navigation" })}
                                        </Typography>
                                    </button>
                                )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Footer;
