import React from "react";

import style from "./style.module.scss";
import { Sizes, Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classnames from "classnames";

interface PropTypes {
    children?: string | JSX.Element | JSX.Element[];
    className?: string;
    size?: Sizes;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LinkButton = (props: PropTypes): JSX.Element => {
    const { children, className, size, ...other } = props;

    return (
        <button className={classnames(style.button, className)} {...other}>
            <Typography variant="link" size={size}>
                {children}
            </Typography>
        </button>
    );
};
