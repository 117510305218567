import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper-2";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { MediaQuery } from "@jmc/solid-design-system/src/components/atoms/MediaQuery/MediaQuery";
import classnames from "classnames";

import style from "./style.module.scss";
import { BreakPoint, useMediaQuery } from "../../../hooks/useMediaQuery";
import { Placement } from "@popperjs/core";

interface Props {
    /**
     * A HTML element, or a function that returns it.
     * It's used to set the position of the menu.
     */
    trigger: JSX.Element;
    /**
     * The label for the menu.
     */
    label?: string;
    /**
     * Whether to disable the fullscreen mode for mobile or not.
     */
    disableFullscreen?: boolean;
    /**
     * Whether to display a separator between the menu items
     */
    displaySeparator?: boolean;
    /**
     * Menu contents, normally MenuItems.
     */
    children: JSX.Element | JSX.Element[];
    /**
     * show animation for menu
     */
    showAnimation?: boolean;
    openMenu?: boolean;
    placement?: Placement;
    setMenuStatus?: (showMenu: boolean) => void;
}

/**
 * Hook to handle the clicks outside the passed ref
 * I had to use this hook instead of useClickAway, because useClickAway wasn't stopping propagation.
 */
function useClickOutside(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                event.stopPropagation();
                callback();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const Menu = ({
    trigger = null,
    label,
    children,
    disableFullscreen = false,
    displaySeparator = false,
    showAnimation = false,
    openMenu = false,
    placement = "auto",
    setMenuStatus = null,
    ...other
}: Props) => {
    const container = useRef();
    const [showMenu, setShowMenu] = useState(openMenu);
    const [referenceElement, setReferenceElement] = useState(null);

    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
    });

    useEffect(() => {
        setShowMenu(openMenu);
    }, [openMenu]);

    const isMobile = useMediaQuery(BreakPoint.md);

    useClickOutside(container, () => {
        if (showAnimation && isMobile) {
            const menuNode: HTMLElement = container.current;
            menuNode && menuNode?.classList?.remove(style.menuOpen);
            menuNode && menuNode?.classList?.add(style.menuClosed);
            setTimeout(() => {
                setShowMenu(false);
            }, 300);
        } else {
            setShowMenu(false);
        }
    });

    return (
        <>
            {!disableFullscreen && (
                <MediaQuery max="sm">
                    <div className={style.fullscreen}>
                        {label && (
                            <Typography size="s" color="primary" className={style.label} dataTestId="Menu.Label">
                                {label}
                            </Typography>
                        )}
                        <div onClick={() => setShowMenu(!showMenu)} data-test-id="Menu.Trigger" role="presentation">
                            {trigger}
                        </div>
                        {showMenu && (
                            <div className={style.menuContainer} data-test-id="Menu.Items">
                                <div
                                    ref={isMobile ? container : null}
                                    className={showAnimation ? style.menuOpen : style.menu}
                                    {...other}
                                >
                                    {React.Children.map(children, (child) =>
                                        React.cloneElement(child, {
                                            onClick: () => {
                                                child.props.onClick();
                                                setShowMenu(false);
                                            },
                                        }),
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </MediaQuery>
            )}
            <MediaQuery min={disableFullscreen ? "xs" : "md"}>
                <div
                    ref={disableFullscreen || (!disableFullscreen && !isMobile) ? container : null}
                    className={classnames(style.default, displaySeparator ? style.withSeparator : null)}
                >
                    <div
                        ref={setReferenceElement}
                        onClick={() => {
                            setShowMenu(!showMenu);
                            setMenuStatus && setMenuStatus(!showMenu);
                        }}
                        data-test-id="Menu.Trigger"
                        role="presentation"
                    >
                        {trigger}
                    </div>
                    {showMenu && (
                        <div
                            data-test-id="Menu.Items"
                            ref={setPopperElement}
                            className={style.menuContainer}
                            style={styles.popper}
                            {...attributes.popper}
                        >
                            <div className={style.menu} {...other}>
                                {React.Children.map(children, (child) => {
                                    return (
                                        child &&
                                        React.cloneElement(child, {
                                            onClick: () => {
                                                child.props.onClick();
                                                setShowMenu(false);
                                            },
                                        })
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </MediaQuery>
        </>
    );
};

interface MenuItemProps {
    children: string;
    onClick?: () => void;
    [x: string]: any;
}

export const MenuItem = ({ children, onClick = () => null, ...other }: MenuItemProps) => {
    const isMobile = useMediaQuery(BreakPoint.lg);
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            onClick();
        }
    };
    return (
        <div
            className={style.menuitem}
            onClick={onClick}
            {...other}
            tabIndex={0}
            onKeyDown={handleKeyPress}
            role="button"
        >
            <Typography size={isMobile ? "default" : "s"} dataTestId={`Menu.Item.${children}`}>
                {children}
            </Typography>
        </div>
    );
};
