import React, { ReactElement } from "react";
import { Quote } from "@jmc/solid-design-system/src/components/atoms/Quote/Quote";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import parse, { domToReact, DOMNode, Element } from "html-react-parser";
import style from "./style.module.scss";
import { Colors } from "../../atoms/Typography/Typography";
import { transformSpecialChars } from "../../../utils/specialChars";
import { Table, Tbody, Td, Th, Tr } from "../../organisms/Table/Table";
import classnames from "classnames";

export type TransformFunction = (
    element: ReactElement<any>,
    color?: Colors,
    children?: string | JSX.Element | JSX.Element[],
) => any;

interface TransformMapping {
    [key: string]: TransformFunction;
}

interface Props {
    children: string;
    /** adjust the base color */
    color?: Colors;
    /** Allows custom tranformation of any element being parsed */
    onTransform?: TransformFunction;
    /** Apply default margins or stick to the global CSS reset */
    margins?: boolean;
}

const htmlMapping: TransformMapping = {
    script() {
        return <></>;
    },
    style() {
        return <></>;
    },
    h1(element, color, children) {
        return (
            <Typography color={color} variant="h1" {...element.props}>
                {children}
            </Typography>
        );
    },
    h2(element, color, children) {
        return (
            <Typography color={color} variant="h2" {...element.props}>
                {children}
            </Typography>
        );
    },
    h3(element, color, children) {
        return (
            <Typography color={color} variant="h3" {...element.props}>
                {children}
            </Typography>
        );
    },
    h4(element, color, children) {
        return (
            <Typography color={color} variant="h4" {...element.props}>
                {children}
            </Typography>
        );
    },
    h5(element, color, children) {
        return (
            <Typography color={color} variant="h5" {...element.props}>
                {children}
            </Typography>
        );
    },
    h6(element, color, children) {
        return (
            <Typography color={color} variant="h6" {...element.props}>
                {children}
            </Typography>
        );
    },
    p(element, color, children) {
        return (
            <Typography color={color} component={"p"} {...element.props}>
                {children}
            </Typography>
        );
    },
    span(element, color, children) {
        return <Typography variant="span">{children}</Typography>;
    },
    em(element, color, children) {
        return (
            <Typography component="em" color="inherit" size="inherit" weight="inherit" font="inherit">
                {children}
            </Typography>
        );
    },
    div(element, color, children) {
        return <div {...element.props}>{children}</div>;
    },
    strong(element, color, children) {
        const result = React.cloneElement(element, [], transformSpecialChars(children));
        return result;
    },
    li(element, color, children) {
        return (
            <li {...element.props}>
                <Typography color={color}>{transformSpecialChars(children)}</Typography>
            </li>
        );
    },
    blockquote(element, color, children) {
        return <Quote {...element.props}>{children}</Quote>;
    },
    table(element, color, children) {
        let { className } = element.props || {};
        className = className?.split(" ");
        const pivotTable = className?.includes("table-pivoted");

        return (
            <div
                data-test-id={pivotTable ? "normal-table" : "scrollbar-table"}
                className={classnames(pivotTable ? style.visibleClass : style.autoClass, style.overflowY)}
            >
                <Table {...element.props} className={pivotTable ? null : "scrollbar"}>
                    {children}
                </Table>
            </div>
        );
    },
    thead(element, color, children) {
        // some how Thead fails here
        return (
            <thead {...element.props}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement<{ inHeader: boolean }>(child as ReactElement, { inHeader: true });
                    }
                })}
            </thead>
        );
    },
    tbody(element, color, children) {
        return <Tbody {...element.props}>{children}</Tbody>;
    },
    tr(element, color, children) {
        return (
            <Tr {...element.props}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child as ReactElement);
                    }
                })}
            </Tr>
        );
    },
    th(element, color, children) {
        return (
            <Th key={element.key} {...element.props}>
                <Typography variant="h6" color="inherit" weight="600">
                    {children}
                </Typography>
            </Th>
        );
    },
    td(element, color, children) {
        return <Td {...{ ...element.props, children: transformSpecialChars(children) }} />;
    },
    img(element) {
        return <img style={{ maxWidth: "100%" }} alt={element?.props?.alt || ""} {...element.props} />;
    },
};

export const Html = ({ children, color, onTransform = () => null, margins = false, ...other }: Props) => {
    const transform = (node: DOMNode) => {
        if (!node || !["tag", "text"].includes(node.type)) {
            return <></>;
        }
        if (node?.type === "text" && node?.data?.match(/^\s*$/)) {
            if (
                (node?.prev?.name === "p" && node?.next?.name === "p") ||
                (node?.prev?.name === "span" && node?.next?.name === "span")
            ) {
                // skip p/span tags that contain nothing but whitespace
                return <></>;
            }
        }

        const element = node as Element;

        const reactElement = domToReact([element]) as JSX.Element;
        const options = { replace: transform, trim: false };
        const reactChildren = element.children ? domToReact(element.children, options) : [];

        const transformedElement = onTransform(reactElement, color, reactChildren);

        if (transformedElement) {
            return transformedElement;
        }
        if (!node.parent && node.type === "text") {
            const text = node as unknown as Text;
            return (
                <Typography component="span" color="inherit" size="inherit" weight="inherit" font="inherit">
                    {text.data}
                </Typography>
            );
        }

        const transformer = htmlMapping[element.name];
        if (transformer) {
            const reactElement = domToReact([element]) as JSX.Element;

            if (element.name === "img") {
                const parent = node.parent as Element;
                const elementStyle = parent?.attribs?.style;

                if (elementStyle) {
                    if (elementStyle.includes("float: left") || elementStyle.includes("float: none")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: flex-start";
                    }
                    if (elementStyle.includes("margin: auto")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: center";
                    }
                    if (elementStyle.includes("float: right")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: flex-end";
                    }
                }
            }
            if (element.attribs?.class) {
                const whiteListedClassNames: string[] = [
                    "text-small",
                    "color-primary",
                    "color-secondary",
                    "color-accent",
                    "color-accent-2",
                    "aepi-box",
                    "align-left",
                    "align-center",
                    "align-right",
                    "scrollbar",
                    "color-black",
                ];

                const classes = element.attribs.class.split(" ");

                const allowedClassName = classes
                    .filter((className: string) => whiteListedClassNames.includes(className))
                    .map((className: string) => style[className]);

                const transformedElm = transformer(reactElement, color, reactChildren);
                return React.cloneElement(transformedElm, {
                    "data-test-id": `HelperClasses.${element.attribs.class}`,
                    ...(classes.includes("rtl") ? { dir: "rtl" } : classes.includes("ltr") && { dir: "ltr" }),
                    ...{ className: classnames(allowedClassName) },
                });
            }

            return transformer(reactElement, color, reactChildren);
        }
    };
    //Replace < to &lt
    children = children?.replace(/<(?![^<]+>)/g, "&lt;");

    const output = children ? parse(children, { replace: transform, trim: true }) : null;

    return margins ? (
        <div {...other} className={style.default_margins}>
            {output}
        </div>
    ) : (
        <div {...other}>{output}</div>
    );
};

export default Html;
