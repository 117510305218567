import analyticstracker from "@jmc/analyticstracker";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { LanguageSwitcher } from "@jmc/solid-design-system/src/components/molecules/LanguageSwitcher/LanguageSwitcher";
import { getLanguage } from "@jmc/solid-design-system/src/utils/languages";
import { navigate } from "gatsby";
import React, { useContext } from "react";

import { langSwitchContext } from "../../contexts/LangSwitchContext";

export const LangSwitcher = (): JSX.Element => {
    const [langList] = useContext(langSwitchContext);
    const currentLocale = useLocale();

    // get the language code from env config files
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");

    const eventTracking = (linkName: string): void => {
        analyticstracker().trackEvent({
            event: EventTypes.NAVIGATION_CLICK,
            info: {
                name: "header_navigation",
                linkname: linkName,
            },
        });
    };

    // Compare list of languages in which content exists with list of local languages.
    if (langList && langList.length > 0) {
        const localLanguages = langList?.map((node: { node: object }) => node?.node);
        const availableLocales = allLanguages?.filter((language) =>
            localLanguages
                ?.map((locale: { publish_details: { locale: string } }) => locale?.publish_details?.locale)
                ?.includes(language),
        );

        const languages =
            allLanguages?.length &&
            allLanguages
                .filter((locale) => locale !== currentLocale)
                .filter((locale) => availableLocales.includes(locale))
                .map((locale) => {
                    const code = locale.split("-").shift();
                    const lang = getLanguage(code);
                    return { code: code.toUpperCase(), name: lang.name, locale };
                });

        return (
            <LanguageSwitcher
                currentLocale={currentLocale}
                languages={languages}
                navigate={navigate}
                eventTracking={eventTracking}
            />
        );
    }

    return null;
};

export default LangSwitcher;
