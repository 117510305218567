import React from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { default as MdiIcon } from "@mdi/react";

export type IconColorsType =
    | "primary"
    | "secondary"
    | "white"
    | "black"
    | "success"
    | "error"
    | "inherit"
    | "primary-light"
    | "text-light"
    | "text-dark"
    | "text-grey"
    | "text-grey-300"
    | "grey-light"
    | "green-light"
    | "disabled";

interface PropTypes {
    icon: string;
    size?: string | "xxs" | "xs" | "small" | "medium" | "large" | "xl" | "xxl";
    color?: IconColorsType;
    verticalAlignMiddle?: boolean; // allows to tweak the vertical alignment of icons; some require it while others don't
    className?: string;
}

export const Icon = ({
    icon,
    size = "medium",
    color = "primary",
    verticalAlignMiddle = false,
    className,
    ...other
}: PropTypes): JSX.Element => {
    let theSize = size;
    switch (size) {
        case "xxs":
            theSize = "0.5rem";
            break;
        case "xs":
            theSize = "1rem";
            break;
        case "small":
            theSize = "1.25rem";
            break;
        case "medium":
            theSize = "1.5rem";
            break;
        case "large":
            theSize = "1.75rem";
            break;
        case "xl":
            theSize = "3rem";
            break;
        case "xxl":
            theSize = "6rem";
            break;
        case "inherit":
            theSize = "1.25em";
            break;
        default:
            break;
    }

    return (
        <span
            className={classnames(
                style.element,
                verticalAlignMiddle && style.verticalAlignMiddle,
                color && style[color],
                className,
            )}
            {...other}
        >
            <MdiIcon path={icon} size={theSize} />
        </span>
    );
};

Icon.displayName = "Icon";
