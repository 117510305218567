//import styles
import style from "./style.module.scss";

//import libraries
import React, { Component, ReactNode } from "react";
import classnames from "classnames";

import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { IconColorsType } from "../Icon/Icon";
import { Sizes } from "../Typography/Typography";
import { transformSpecialChars } from "../../../utils/specialChars";

//declare types
export type ButtonSizes = "small" | "medium" | "large" | "xlarge";
export type Variants = "contained" | "outlined" | "icon" | "naked";
export type Colors =
    | "primary"
    | "primaryComplex"
    | "secondary"
    | "accent"
    | "accent-2"
    | "success"
    | "warning"
    | "error"
    | "grey"
    | "green"
    | "red"
    | "white"
    | "transparent"
    | "yellow";

//describe our props
export interface PropTypes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    startIcon?: string;
    endIcon?: string;
    iconColor?: IconColorsType;
    accessoryLeft?: JSX.Element;
    accessoryRight?: JSX.Element;
    /** The text that will be displayed inside the button*/
    children?: string | ReactNode;
    /** All the different variants of our button*/
    variant?: Variants;
    /** All the different colors of our button */
    color?: Colors;
    /** All the different sizes of our button */
    size?: ButtonSizes;
    /** Is the button disabled? */
    disabled?: boolean;
    /** Should the button have rounded edges */
    rounded?: boolean;
    /** Should the button be a circle */
    circle?: boolean;
    /** Is our button a floating action button */
    floating?: boolean;
    /** Callback of our OnClick event */
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    active?: boolean;
    fullWidth?: boolean;
    justifiedBetween?: boolean;
    tabIndex?: number;
    buttonRef?: MutableRefObject<any>;
}

const TEXT_SIZE = {
    small: "s",
    medium: "m",
    large: "l",
};

const ICON_SIZE = {
    small: "0.8rem",
    medium: "1rem",
    large: "1.25rem",
    xlarge: "1.5rem",
};

export class Button extends Component<PropTypes> {
    static defaultProps = {
        size: "medium",
        variant: "contained",
        color: "primary",
        rounded: true,
        active: false,
        iconColor: "inherit",
        justifiedBetween: false,
    };

    render(): JSX.Element {
        const {
            startIcon,
            endIcon,
            iconColor,
            accessoryLeft,
            accessoryRight,
            children,
            variant,
            color,
            size,
            disabled,
            rounded,
            floating,
            onClick,
            circle,
            id,
            active,
            fullWidth,
            justifiedBetween,
            tabIndex = 0,
            buttonRef,
            ...other
        } = this.props;

        const classNames = classnames(
            style.element,
            style[variant],
            style[`${variant}__${color}`],
            variant !== "icon" ? style[size] : null,
            rounded && !floating ? style[`element__rounded`] : null,
            circle && floating ? style["floating__circle"] : null,
            !circle && rounded && floating ? style[`floating__rounded`] : null,
            floating ? style.floating : null,
            disabled ? style.disabled : null,
            startIcon ? style.withStartIcon : null,
            endIcon ? style.withEndIcon : null,
            accessoryLeft ? style.withAccessoryLeft : null,
            accessoryRight ? style.withAccessoryRight : null,
            active ? style.active : null,
            fullWidth ? style.fullWidth : null,
            justifiedBetween ? style.justifiedBetween : style.justifiedDefault,
        );

        const transformChildren = transformSpecialChars(children);
        return (
            <button
                aria-label={id}
                className={classNames}
                disabled={disabled}
                id={id}
                onClick={onClick}
                tabIndex={tabIndex}
                ref={buttonRef}
                {...other}
            >
                {accessoryLeft}
                <Typography weight="600" color="inherit" size={TEXT_SIZE[size === "xlarge" ? "medium" : size] as Sizes}>
                    {startIcon ? (
                        <Icon
                            icon={startIcon}
                            color={iconColor}
                            size={ICON_SIZE[size]}
                            data-test-id={`Button.StartIcon`}
                        />
                    ) : null}
                    <span className={style.content} data-test-id={`Button.Children.${id}`}>
                        {transformChildren}
                    </span>
                    {endIcon ? (
                        <Icon icon={endIcon} color={iconColor} size={ICON_SIZE[size]} data-test-id={`Button.EndIcon`} />
                    ) : null}
                </Typography>
                {accessoryRight}
            </button>
        );
    }
}
