import classnames from "classnames";
import React from "react";
import { keyHandler } from "@jmc/utils/utils/keyHandler";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";

import styles from "./style.module.scss";

interface PropTypes {
    open: boolean;
    className?: string;
    openText?: string;
    closeText?: string;
    [x: string]: unknown;
}

/**
 * Hambuger icon with animation.
 */
export const Hamburger = ({ open = false, className, openText, closeText, ...other }: PropTypes): JSX.Element => {
    return (
        <a
            className={classnames(className, styles.container)}
            tabIndex={0}
            onKeyDown={(e: KeyboardEvent): void => keyHandler(e.key, () => other?.onClick())}
            role="button"
            {...other}
        >
            <div className={classnames(styles.hamburger, open ? styles.open : null)}>
                <span className={classnames(styles.bar, styles.bar1)} />
                <span className={classnames(styles.bar, styles.bar2)} />
                <span className={classnames(styles.bar, styles.bar3)} />
            </div>
            {openText && closeText && (
                <Typography color="primary-general" size="xs">
                    {open ? closeText : openText}
                </Typography>
            )}
        </a>
    );
};

export default Hamburger;
