import React from "react";
import {
    AutoCompleteType,
    AUTOCOMPLETE_RESULTS,
    QUERY_SUGGESTIONS,
    RECENT_SEARCHES,
    RECENT_SEARCHES_PLUGIN_NAME,
} from "../types";
import classnames from "classnames";
import style from "./style.module.scss";
import Suggestions from "../Suggestions/Suggestions";
import AutoCompleteResults from "../AutoCompleteResults/AutoCompleteResults";
import RecentSearches from "../RecentSearches/RecentSearches";

export const Collection = ({
    collection,
    autocomplete,
    query,
    plugins,
}: {
    collection: {
        source: { sourceId: string };
        items: Record<string, unknown>[];
    };
    autocomplete: AutoCompleteType;
    query: string;
    plugins: { data: { removeItem: (id: string) => void }; name: string }[];
}): JSX.Element => {
    const { source, items } = collection;

    const displayAutocompleteResults = items.length > 0 && source.sourceId === AUTOCOMPLETE_RESULTS && query !== "";
    const displayRecentSearches = items.length > 0 && source.sourceId === RECENT_SEARCHES && query === "";
    const displayQuerySuggestions = items.length > 0 && source.sourceId === QUERY_SUGGESTIONS && query === "";

    if (!displayAutocompleteResults && !displayRecentSearches && !displayQuerySuggestions) {
        return null;
    }

    return (
        <div className={classnames("aa-Source", style.source)}>
            {displayAutocompleteResults && (
                <AutoCompleteResults items={items} autocomplete={autocomplete} source={source} />
            )}
            {displayRecentSearches && (
                <RecentSearches
                    items={items}
                    autocomplete={autocomplete}
                    source={source}
                    plugin={plugins.find((plugin) => plugin.name === RECENT_SEARCHES_PLUGIN_NAME)}
                />
            )}
            {displayQuerySuggestions && <Suggestions items={items} autocomplete={autocomplete} source={source} />}
        </div>
    );
};

export default Collection;
