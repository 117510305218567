import { CMSFootnotes } from "@jwm/types/CMSFootnotes";
import { MutableRefObject } from "react";
import { DefaultRootState } from "react-redux";
import { combineReducers } from "redux";

import askTheExperts, { AskTheExperts } from "./askTheExperts";
import askTheExpertsQuestions, { AskTheExpertsQuestions } from "./askTheExpertsQuestions";
import authValid, { AuthData } from "./authValid";
import contactRequest, { ContactRequests } from "./contactRequest";
import contactRequestDisclaimer from "./contactRequestDisclaimer";
import contactRequestProfile, { ContactRequestProfile } from "./contactRequestProfile";
import contactRequestTab from "./contactRequestTab";
import contactRequestViewProfile, { ContactRequestViewProfileState } from "./contactRequestViewProfile";
import footnotes from "./footnotes";
import pageSpecialty, { PageSpecialty } from "./pageSpecialty";
import panes, { Panes } from "./panes";
import profile, { Profile } from "./profile";
import videoPlayer from "./videoPlayer";
import voiceSearchStatus from "./voiceSearchStatus";

export interface ApplicationState extends DefaultRootState {
    authValid: AuthData;
    askTheExperts: AskTheExperts;
    askTheExpertsQuestions: AskTheExpertsQuestions;
    contactRequest: ContactRequests;
    profile: Profile;
    contactRequestViewProfile: ContactRequestViewProfileState;
    contactRequestProfile: ContactRequestProfile;
    pageSpecialty: PageSpecialty;
    videoPlayer: { videoref: MutableRefObject<unknown> };
    voiceSearchStatus: { isBrowserSupported: boolean; isListening: boolean };
    contactRequestTab: { activeTabIndex: number };
    contactRequestDisclaimer: { status: boolean };
    footnotes: CMSFootnotes[];
    panes: Panes;
}

/**
 * The root reducer
 */
export default combineReducers<ApplicationState>({
    authValid,
    askTheExperts,
    askTheExpertsQuestions,
    contactRequest,
    profile,
    contactRequestProfile,
    contactRequestViewProfile,
    pageSpecialty,
    videoPlayer,
    voiceSearchStatus,
    contactRequestTab,
    contactRequestDisclaimer,
    footnotes,
    panes,
});
