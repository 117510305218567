import React from "react";
import Highlight from "../Highlight/Highlight";
import { AutoCompleteType } from "../types";
import classnames from "classnames";
import style from "./style.module.scss";

export const AutoCompleteResults = ({
    items,
    autocomplete,
    source,
}: {
    items: Record<string, unknown>[];
    autocomplete: AutoCompleteType;
    source: { sourceId: string };
}): JSX.Element => {
    return (
        <div data-test-id="autocomplete-results">
            <ul className={classnames("aa-List", style.list)} {...autocomplete.getListProps()}>
                {items?.map((item) => (
                    <li
                        key={`autocomplete-${source?.sourceId}-${item?.url}`}
                        data-test-id={`autocomplete-result-${item?.page_title}`}
                        className={classnames("aa-Item", style.item)}
                        {...autocomplete.getItemProps({
                            item,
                            source,
                        })}
                    >
                        <Highlight attribute={item?.["page_title"] ? "page_title" : "title"} item={item} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AutoCompleteResults;
