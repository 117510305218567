import React from "react";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    locale: string;
}

const FontWrapper: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, locale } = props;
    /* Array of languages which has to include Verdana Pro font */
    const langArray = ["el-gr", "he-il"];
    const lang = locale?.split("-");
    if ((lang != undefined && lang?.[0] == "ru") || langArray.includes(locale)) {
        const variables = {
            "--text-font": "Verdana Pro",
            "--title-font": "Verdana Pro",
        } as React.CSSProperties;
        return (
            <div style={variables} data-test-id="font-family">
                {children}
            </div>
        );
    }

    return <>{children}</>;
};

export default FontWrapper;
