import { ModalImpression } from "@components/ModalImpression/ModalImpression";
import { FontWrapper } from "@jmc/core/src/components/FontWrapper/index";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Modal } from "@jmc/solid-design-system/src/components/molecules/Modal/Modal";
import React, { ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";

interface PropTypes {
    title: string;
    url?: string;
    open: boolean;
    OKButtonText?: string;
    CancelButtonText?: string;
    onClose?: () => void;
    onSubmit?: () => void;
    onCancel?: () => void;
    children: ReactNode;
    locale?: string;
    type?: string;
}

/**
 * Presentation component for modal dialogs.
 */
export const JWMLinkModal = ({
    title,
    url,
    open,
    OKButtonText,
    CancelButtonText,
    onClose,
    onSubmit,
    onCancel,
    children,
    locale,
    type = "Disclaimer",
}: PropTypes): JSX.Element => {
    const { t } = useTranslation();
    const okButtonRef = useRef(null);

    useEffect(() => {
        if (open) {
            okButtonRef?.current && okButtonRef?.current?.focus();
        }
    }, [open]);

    const OKButton = (
        <Button
            color="secondary"
            onClick={(): void => {
                onClose && onClose();
                onSubmit && onSubmit();
            }}
            data-test-id="Modal.Ok"
            data-tracking-action="Ok"
            key="ok.button"
            buttonRef={okButtonRef}
        >
            {OKButtonText ? OKButtonText : t("Continue", { ns: "common" })}
        </Button>
    );

    return (
        <ModalImpression open={open} type={type}>
            <Modal
                aria-modal="true"
                open={open}
                onClose={onClose}
                position="topRight"
                spacing="large"
                data-test-id="Modal"
                size="medium"
            >
                <FontWrapper locale={locale}>
                    <Modal.Title title={title} key="title" />
                    <Modal.Content key="content">{children}</Modal.Content>
                    <Modal.Actions key="actions">
                        {url ? (
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a key="url" className={style.link} href={url} target="_blank" rel="noopener">
                                {OKButton}
                            </a>
                        ) : (
                            OKButton
                        )}
                        <Button
                            key="button"
                            color="secondary"
                            variant="outlined"
                            onClick={(): void => {
                                onClose && onClose();
                                onCancel && onCancel();
                            }}
                            data-test-id="Modal.Cancel"
                            data-tracking-action="Cancel"
                        >
                            {CancelButtonText ? CancelButtonText : t("Cancel", { ns: "common" })}
                        </Button>
                    </Modal.Actions>
                </FontWrapper>
            </Modal>
        </ModalImpression>
    );
};

export default JWMLinkModal;
