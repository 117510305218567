/**
 * Find the element with the given anchor id.
 */
const findElement = (anchor: string): HTMLElement => {
    const id = (anchor && anchor.startsWith("#") ? anchor.substring(1) : anchor) || null;
    const element = document.getElementById(id) || document.getElementById(decodeURI(id)) || null;

    return element;
};

/**
 * Scrolls to the anchor with offset of navigation
 * @param anchor id of the anchor
 */
export const anchorScroll = (
    anchor: string,
    tocOffset?: number,
    preventExpand?: boolean,
    scrollOffset?: number,
): void => {
    const elem = findElement(anchor);

    const headerHeight = findElement("main-header")?.offsetHeight || 0;
    const tocHeight = tocOffset || findElement("toc-wrapper-id")?.offsetHeight || 0;
    const totalHeight = headerHeight + tocHeight - (scrollOffset || 0);

    const style = elem?.getAttribute("style") || "";

    //using scroll into view instead of scroll to to benefit from scroll margin top
    //scroll margin top allow a virtual margin at the top of an element to stop the scrolling going further up
    elem?.setAttribute("style", `${style} scroll-margin-top: ${totalHeight}px;`);

    if (elem?.scrollIntoView) {
        elem?.scrollIntoView({ behavior: "smooth" });
    }
    const event = new CustomEvent("anchorScrolled", { bubbles: true });
    findElement("main-header")?.dispatchEvent(event);
    if (preventExpand) {
        const collapsed = new CustomEvent("anchorCollapsedScroll", { bubbles: true });
        findElement("main-header")?.dispatchEvent(collapsed);
    }
};

export default anchorScroll;
