import style from "./style.module.scss";

import React, { ReactNode, useRef } from "react";
import classnames from "classnames";
import { transformSpecialChars } from "../../../utils/specialChars";
import { ToolTipContext } from "./ToolTipContext/ToolTipContext";

type VariantMappings = {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    body: string;
    helperText: string;
    label: string;
    micro: string;
    span: string;
    label__dark: string;
    navigation: string;
    link: string;
};

type Variants =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "body"
    | "helperText"
    | "label"
    | "micro"
    | "span"
    | "label__dark"
    | "link"
    | "navigation";

export type Colors =
    | "primary"
    | "secondary"
    | "accent"
    | "success"
    | "warning"
    | "error"
    | "gray"
    | "inherit"
    | "white"
    | "title"
    | "dark"
    | "light"
    | "primary-general"
    | "primary-300"
    | "primary-400"
    | "primary-bg"
    | "secondary-general"
    | "secondary-300"
    | "secondary-400"
    | "secondary-bg"
    | "accent-300"
    | "accent-400"
    | "accent-2"
    | "accent-bg"
    | "disabled"
    | "green-light";

export type LineHeight = "default" | "l";

export type Weights = "300" | "400" | "500" | "600" | "700" | "800" | "900" | "inherit";

export type Sizes = "2xs" | "xs" | "s" | "default" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "inherit";

export type Fonts = "title" | "text" | "navigation" | "inherit";

interface PropTypes {
    [x: string]: any;
    children: string | ReactNode;
    className?: string;
    color?: Colors;
    component?: React.ElementType;
    dataTestId?: string;
    font?: string;
    italic?: boolean;
    underline?: boolean;
    lineHeight?: LineHeight;
    link?: boolean;
    paddingToLink?: "none" | "S" | "M" | "L";
    paragraph?: boolean;
    size?: Sizes;
    variant?: Variants;
    variantMapping?: VariantMappings;
    weight?: Weights;
    wordBreak?: string;
    ignoreCopyrightSuperscripting?: boolean;
}

const defaultVariantMapping: VariantMappings = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    body: "div",
    helperText: "p",
    label: "label",
    micro: "p",
    span: "span",
    label__dark: "label",
    navigation: "nav",
    link: "span",
};

export const Typography = (props: PropTypes) => {
    const ref = useRef();

    const isTitle = () => !!props.variant?.match(/^h./);

    const shouldUseTitleColor = () => isTitle() && !props.color;
    const defaultFontWeight = () => (isTitle() ? 500 : 400);

    const {
        children,
        className,
        color = shouldUseTitleColor() ? "title" : "primary",
        component,
        dataTestId = "Typography",
        font = isTitle() ? "title" : "text",
        italic = false,
        underline = false,
        lineHeight = "default",
        link = false,
        paddingToLink = "none",
        paragraph = false,
        size = "",
        variant = "body",
        variantMapping = defaultVariantMapping,
        weight = defaultFontWeight(),
        wordBreak = "break-word",
        ignoreCopyrightSuperscripting = false,
        ...other
    } = props;

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component: any =
        component || (paragraph ? "p" : variantMapping[variant] || defaultVariantMapping[variant]) || "span";

    const propsBlacklist = ["style", "className"];
    const stylesWhitelist = ["textAlign"]; // we allow the things that can be set in the RTE of our CMS

    const allowOtherProps = Object.entries(other)
        .filter(([key, value]) => {
            if (key === "style" && stylesWhitelist.includes(Object.keys(value)?.[0])) return true;
            else return !propsBlacklist.includes(key);
        })
        .reduce((obj, [key]) => {
            return {
                ...obj,
                [key]: other[key],
            };
        }, {});

    const finalChildren = transformSpecialChars(children, ignoreCopyrightSuperscripting);
    return (
        <Component
            className={classnames(
                className,
                italic ? style.italic : "",
                underline ? style.underline : "",
                lineHeight && lineHeight !== "default" ? style[`lineHeight_${lineHeight}`] : null,
                link ? style.link : "",
                paddingToLink != "none" ? style[`padding-${paddingToLink}`] : "",
                size ? style["size-" + size] : "",
                wordBreak !== "normal" ? style.element : "",
                style["font-" + font],
                style[`weight-${weight}`],
                style[color],
                style[variant],
            )}
            ref={ref}
            data-test-id={dataTestId}
            {...allowOtherProps}
        >
            {finalChildren}
        </Component>
    );
};

Typography.tooltipContext = ToolTipContext;
Typography.displayName = "Typography";
