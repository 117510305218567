import classnames from "classnames";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import React from "react";
import style from "./style.module.scss";
import { ModalConsumer } from "./Modal";

export interface PropTypes {
    title: string | JSX.Element;
    align?: "start" | "center" | "end";
}

export const Title = ({ title, align = "start" }: PropTypes): JSX.Element => (
    <ModalConsumer>
        {() => (
            <div className={classnames(style.modal_title, style[`align_${align}`])}>
                <Typography variant="h5" color="primary" data-test-id="Modal.Title">
                    {title}
                </Typography>
            </div>
        )}
    </ModalConsumer>
);
