import { CMSSiteColors } from "@jwm/types/CMSMinisite";
import tinycolor from "tinycolor2";

const getCustomColorsCSS = (custom_colors: CMSSiteColors): React.CSSProperties => {
    const convertToBg = (color: string): unknown => {
        return tinycolor.fromRatio({ h: tinycolor(color).toHsl().h, s: 32, l: 88 }).toHexString();
    };

    const useCustomColors =
        custom_colors?.use_custom_colors && custom_colors?.primary_color && custom_colors?.secondary_color;
    const primary = custom_colors?.primary_color;
    const primaryBg = primary && convertToBg(primary);
    const secondary = custom_colors?.secondary_color;
    const secondaryBg = secondary && convertToBg(secondary);
    const accent = custom_colors?.accent_color;
    const accentBg = accent && convertToBg(accent);
    const variables = useCustomColors
        ? ({
              "--primary": primary,
              "--title": primary,
              "--text-link": primary,
              "--text-link-visited": primary,
              "--primary-400": tinycolor(primary).darken(10).toString(),
              "--primary-300": tinycolor(primary).lighten(10).toString(),
              "--primary-bg": primaryBg,
              "--text-secondary": secondary,
              "--secondary": secondary,
              "--secondary-400": tinycolor(secondary).darken(10).toString(),
              "--secondary-300": tinycolor(secondary).lighten(10).toString(),
              "--secondary-bg": secondaryBg,
              "--accent": accent ? accent : "initial",
              "--accent-400": accent && tinycolor(accent).darken(10).toString(),
              "--accent-300": accent && tinycolor(accent).lighten(10).toString(),
              "--accent-bg": accent && accentBg,
          } as React.CSSProperties)
        : ({} as React.CSSProperties);

    return variables;
};

export default getCustomColorsCSS;
