import style from "./style.module.scss";

import React, { ReactNode, useRef } from "react";
import classnames from "classnames";

interface Props {
    component?: React.ElementType;
    square?: boolean;
    children: ReactNode;
    className?: string;
    onClick?: () => void;
    spacing?: "none" | "extra-small" | "small" | "medium" | "24" | "28" | "large" | "extra-large";
}

export const Paper = ({
    component: Component = "div",
    square = false,
    className,
    onClick,
    spacing = "medium",
    children,
    ...other
}: Props) => {
    const ref = useRef();
    return (
        <Component
            onClick={onClick}
            className={classnames(style.element, !square ? style.rounded : null, className)}
            ref={ref}
            data-test-id="paper"
            {...other}
        >
            <div className={classnames(style.content, style[`spacing_${spacing}`])}>{children}</div>
        </Component>
    );
};

Paper.displayName = "Paper";
