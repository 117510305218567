// Transforms a url path into the corresponding locale
export const createLocale = (path: string, index = 1, gatsbyLanguages: Array<string>): string => {
    if (!path) return process.env.FALLBACK_LOCALE;
    const locale = path.split("/")[index];
    const validPathName = gatsbyLanguages?.includes(locale);
    //console.log(validPathName ? `path ${path} is using locale ${locale}` : `${path} doesn't have a valid locale`);

    return validPathName ? locale : process.env.FALLBACK_LOCALE;
};

export default createLocale;
