import React, { CSSProperties, useState } from "react";
import { usePopper } from "react-popper-2";
import { Placement } from "@popperjs/core";
import usePortal from "react-useportal";

import style from "./style.module.scss";
import classnames from "classnames";
import { Typography } from "../../atoms/Typography/Typography";

//popper solution for storybook: https://github.com/storybookjs/storybook/issues/10982#issuecomment-642518186

interface Props {
    text: string | JSX.Element;
    children: string | JSX.Element;
    linkText?: string;
    fullLength?: boolean;
    placement?: Placement;
    extraStyles?: CSSProperties;
}

export const Tooltip: React.FunctionComponent<Props> = ({
    text,
    children,
    linkText,
    fullLength = false,
    placement = "top",
    extraStyles,
    ...otherProps
}: Props) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [openPortal, closePortal, isOpen, Portal] = usePortal();
    const { styles, attributes, ...other } = usePopper(referenceElement, popperElement, {
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            {
                name: "offset",
                options: {
                    offset: [0, 3],
                },
            },
        ],
        placement,
    });

    return (
        <span
            className={style.element}
            data-test-id="Tooltip"
            onMouseEnter={openPortal}
            onMouseLeave={closePortal}
            key={(text as string) || linkText}
        >
            <span ref={setReferenceElement}>{children}</span>
            {isOpen && (
                <Portal>
                    <span
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                        className={classnames(style.tooltip, style[other.state?.placement])}
                        {...otherProps}
                    >
                        <span style={extraStyles}>
                            {typeof text === "string" ? (
                                <span
                                    data-test-id="Tooltip.Text"
                                    className={classnames(style.text, fullLength && style.fullLength)}
                                >
                                    <Typography variant="span">{text}</Typography>
                                </span>
                            ) : (
                                <span
                                    data-test-id="Tooltip.Text"
                                    className={classnames(style.text, fullLength && style.fullLength)}
                                >
                                    {text}
                                </span>
                            )}
                            <span ref={setArrowElement} style={styles.arrow} className={style.tooltipArrow}>
                                <span className={style.arrow} />
                            </span>
                        </span>
                    </span>
                </Portal>
            )}
        </span>
    );
};
