import { AutocompleteState, BaseItem, createAutocomplete } from "@algolia/autocomplete-core";
import { AUTOCOMPLETE_RESULTS } from "./types";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import { SearchClient } from "algoliasearch/lite";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createVoiceSearchPlugin } from "./VoiceSearch/voiceSearchPlugin";

export const buildAutocomplete = (
    searchClient: SearchClient,
    indexName: string,
    setAutocompleteState: (state: AutocompleteState<BaseItem>) => void,
    onSelectCallback: (value: string) => void,
    plugins: Record<string, unknown>[],
    initialState?: unknown,
    attribute = "page_title",
    contentType?: string,
    enableAutocomplete = true,
    siteFilter?: string,
) => {
    let filters = contentType ? `content_type:'${contentType}'` : "";
    if (siteFilter) {
        if (contentType) filters += " AND ";
        filters += `site_title:'${siteFilter}'`;
    }

    const isValidPath = (path: string) => {
        // Starts with /, followed by non-special characters
        const pathRegex = /^\/[^#\s?]*(#.+)?$/;
        return pathRegex.test(path);
    };

    return createAutocomplete({
        initialState: initialState,
        onStateChange({ state }) {
            setAutocompleteState(state);
        },
        getSources() {
            return [
                {
                    sourceId: AUTOCOMPLETE_RESULTS,
                    getItemInputValue({ item }) {
                        return item?.[attribute];
                    },
                    getItems({ query }) {
                        if (!enableAutocomplete) {
                            return [];
                        }
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: indexName,
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                        highlightPreTag: "<mark>",
                                        highlightPostTag: "</mark>",
                                        filters: filters,
                                    },
                                },
                            ],
                            transformResponse({ hits }) {
                                // Only returns hits with uri
                                const hitsWithUri = hits.flatMap((hitArray) =>
                                    hitArray.filter((hit: { uri?: string }) => isValidPath(hit.uri)),
                                );
                                return [hitsWithUri];
                            },
                        });
                    },
                    onSelect(params) {
                        params.setIsOpen(false);
                        const value = (params?.item?.[attribute] ||
                            params?.item?.["page_title"].replace(/<[^>]+>/g, "")) as string;
                        params.setQuery(value);
                        onSelectCallback(value);
                    },
                },
            ];
        },
        plugins,
        reshape({ sources, sourcesBySourceId, state }) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { recentSearchesPlugin, querySuggestionsPlugin, ...rest } = sourcesBySourceId;
            // If the query is empty we are including recent searches and query suggestions plugin, But if there is a query we are ignoring recent searches and query suggestions plugins to make sure the keyboard navigation works fine
            if (state.query) {
                return [Object.values(rest)];
            } else {
                return sources;
            }
        },
        detachedMediaQuery: "none",
        openOnFocus: true,
    });
};

export const buildPlugins = (
    searchClient: SearchClient,
    indexName: string,
    onSelectCallback: (value: string) => void,
    voiceSearchButton?: (children: JSX.Element) => void,
    locale?: string,
    enablePreviousSearches = true,
    enablePopularSearches = true,
) => {
    const plugins = [];

    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
        key: "search",
        limit: 5,
        transformSource({ source }) {
            return {
                ...source,
                onSelect(params) {
                    onSelectCallback(params?.item?.label);
                },
            };
        },
    });

    if (enablePreviousSearches) {
        plugins.push(recentSearchesPlugin);
    }

    if (enablePopularSearches) {
        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: indexName,
            getSearchParams() {
                return recentSearchesPlugin?.data?.getAlgoliaSearchParams();
            },
            transformSource({ source }) {
                return {
                    ...source,
                    onSelect(params) {
                        onSelectCallback(params?.item?.query);
                    },
                };
            },
        });
        plugins.push(querySuggestionsPlugin);
    }

    if (voiceSearchButton) {
        const voiceSearchPlugin = createVoiceSearchPlugin(locale, voiceSearchButton);
        plugins.push(voiceSearchPlugin);
    }

    return plugins;
};
