import { SnackBar } from "@jmc/solid-design-system/src/components/atoms/SnackBar/SnackBar";
import { useClickOutside } from "@jmc/solid-design-system/src/hooks/useClickOutside";
import { mdiAlertCircleOutline, mdiCheck } from "@mdi/js";
import React, { Fragment, useEffect, useRef, useState } from "react";

import style from "./style.module.scss";

interface PropTypes {
    message: string;
    closeAfterSec?: number;
    onClose?: () => void;
    type?: "error" | "success";
}

export const ErrorMessage = ({ message, closeAfterSec = 5, type = "error", onClose }: PropTypes): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [action, setAction] = useState(null);

    const timer = useRef(null);

    const containerRef = useRef(null);

    useEffect(() => {
        if (closeAfterSec > 0) {
            timer.current = setTimeout(() => {
                setOpen(false);
                setAction("disappeared over time");
                onClose && onClose();
            }, closeAfterSec * 1000);
        }
    }, []);

    useEffect(() => {
        if (action) {
            clearTimeout(timer.current);
        }
    }, [action]);

    const userClosed = (): void => {
        setOpen(false);
        setAction("user closed");
        onClose && onClose();
    };

    useClickOutside(containerRef, userClosed);

    return (
        <Fragment>
            {open && (
                <div
                    className={style.error}
                    ref={containerRef}
                    onClick={(e) => {
                        const isSame = containerRef?.current === e?.target;
                        if (isSame) {
                            userClosed();
                        }
                    }}
                    role="presentation"
                >
                    <SnackBar onClose={userClosed} color={type}>
                        <SnackBar.Icon
                            icon={type === "success" ? mdiCheck : mdiAlertCircleOutline}
                            data-test-id="SnackBar.Icon"
                        />
                        <SnackBar.Text text={message} data-test-id="SnackBar.Text" />
                    </SnackBar>
                </div>
            )}
        </Fragment>
    );
};

export default ErrorMessage;
